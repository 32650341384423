import React from "react";

export const SolanaIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M24 48C37.2548 48 48 37.2548 48 24C48 10.7452 37.2548 0 24 0C10.7452 0 0 10.7452 0 24C0 37.2548 10.7452 48 24 48Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.8105 30.6157C14.9009 30.5163 15.0109 30.437 15.1337 30.3826C15.2563 30.3283 15.3892 30.3002 15.5233 30.3001L37.5169 30.3181C37.6106 30.3183 37.7022 30.3457 37.7806 30.3969C37.859 30.4481 37.9208 30.5211 37.9585 30.6068C37.9963 30.6925 38.0083 30.7874 37.9932 30.8798C37.9781 30.9722 37.9364 31.0582 37.8733 31.1275L33.1897 36.2845C33.0994 36.3838 32.9892 36.4633 32.8664 36.5176C32.7436 36.572 32.6107 36.6001 32.4763 36.6001L10.4834 36.5821C10.3897 36.5818 10.2981 36.5545 10.2197 36.5032C10.1413 36.452 10.0795 36.379 10.0417 36.2933C10.004 36.2077 9.99195 36.1127 10.0071 36.0203C10.0222 35.9279 10.0639 35.8419 10.127 35.7727L14.8105 30.6157ZM37.8733 26.3227C37.9364 26.3919 37.9781 26.4779 37.9932 26.5703C38.0083 26.6627 37.9963 26.7577 37.9585 26.8433C37.9208 26.929 37.859 27.002 37.7806 27.0532C37.7022 27.1045 37.6106 27.1318 37.5169 27.1321L15.5239 27.1501C15.3896 27.1501 15.2568 27.122 15.1339 27.0676C15.011 27.0133 14.9009 26.9338 14.8105 26.8345L10.127 21.6745C10.0639 21.6052 10.0222 21.5192 10.0071 21.4268C9.99195 21.3344 10.004 21.2395 10.0417 21.1538C10.0795 21.0681 10.1413 20.9951 10.2197 20.9439C10.2981 20.8927 10.3897 20.8653 10.4834 20.8651L32.4769 20.8471C32.6112 20.8472 32.7439 20.8753 32.8667 20.9296C32.9894 20.984 33.0995 21.0633 33.1897 21.1627L37.8733 26.3227ZM14.8105 11.7157C14.9009 11.6163 15.0109 11.537 15.1337 11.4826C15.2563 11.4283 15.3892 11.4001 15.5233 11.4001L37.5169 11.4181C37.6106 11.4182 37.7022 11.4456 37.7806 11.4969C37.859 11.5481 37.9208 11.621 37.9585 11.7068C37.9963 11.7925 38.0083 11.8873 37.9932 11.9798C37.9781 12.0722 37.9364 12.1582 37.8733 12.2275L33.1897 17.3845C33.0994 17.4838 32.9892 17.5633 32.8664 17.6176C32.7436 17.672 32.6107 17.7001 32.4763 17.7001L10.4834 17.6821C10.3897 17.6818 10.2981 17.6545 10.2197 17.6032C10.1413 17.552 10.0795 17.479 10.0417 17.3933C10.004 17.3077 9.99195 17.2127 10.0071 17.1203C10.0222 17.0279 10.0639 16.9419 10.127 16.8727L14.8105 11.7157Z"
      fill="url(#paint0_linear)"
    />
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="10.8506"
        y1="37.1497"
        x2="37.1497"
        y2="10.8505"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#9945FF" />
        <stop offset="0.2" stopColor="#7962E7" />
        <stop offset="1" stopColor="#00D18C" />
      </linearGradient>
    </defs>
  </svg>
);

export const ConnectDots: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="56"
    height="12"
    viewBox="0 0 56 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx="19.5" cy="2.5" r="2.5" fill="#DEDEDE" />
    <circle cx="36.5" cy="2.5" r="2.5" fill="#DEDEDE" />
    <circle cx="2.5" cy="2.5" r="2.5" fill="#DEDEDE" />
    <circle cx="53.5" cy="2.5" r="2.5" fill="#DEDEDE" />
    <circle cx="12.5" cy="9.5" r="2.5" fill="#DEDEDE" />
    <circle cx="29.5" cy="9.5" r="2.5" fill="#DEDEDE" />
    <circle cx="46.5" cy="9.5" r="2.5" fill="#DEDEDE" />
  </svg>
);

export const LockIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M4 9.60005C4 8.73069 4 8.29601 4.13285 7.94994C4.33597 7.42079 4.75409 7.00267 5.28325 6.79954C5.62931 6.66669 6.06399 6.66669 6.93336 6.66669H10.8445C11.7139 6.66669 12.1485 6.66669 12.4946 6.79954C13.0238 7.00267 13.4419 7.42079 13.645 7.94994C13.7779 8.29601 13.7779 8.73069 13.7779 9.60005V9.60005C13.7779 10.9041 13.7779 11.5561 13.5786 12.0752C13.2739 12.8689 12.6467 13.4961 11.853 13.8008C11.3339 14.0001 10.6819 14.0001 9.37782 14.0001H8.40003C7.09599 14.0001 6.44397 14.0001 5.92487 13.8008C5.13114 13.4961 4.50396 12.8689 4.19927 12.0752C4 11.5561 4 10.9041 4 9.60005V9.60005Z"
      stroke="black"
      strokeWidth="2"
    />
    <path
      d="M11.3334 6.05558V5.44446C11.3334 4.09442 10.239 3 8.88892 3V3C7.53888 3 6.44446 4.09442 6.44446 5.44446V6.05558"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <ellipse cx="8.88892" cy="10.3334" rx="1.22223" ry="1.22223" fill="black" />
  </svg>
);

export const BoltIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.66447 10.8127H8.53714V13.9986L8.53714 14.0272C8.53712 14.3463 8.53711 14.6243 8.55872 14.8253C8.56961 14.9265 8.58976 15.0525 8.64129 15.1678C8.69746 15.2935 8.81988 15.4635 9.05182 15.5146C9.28375 15.5657 9.4663 15.4628 9.57008 15.3723C9.66527 15.2894 9.73648 15.1835 9.7889 15.0962C9.89294 14.9229 10.0097 14.6707 10.1438 14.381L10.1558 14.3551L12.1557 10.0354L12.171 10.0023C12.3861 9.53761 12.5693 9.14202 12.6647 8.81793C12.7646 8.47858 12.804 8.1028 12.5828 7.75669C12.3617 7.41059 12.0041 7.28844 11.6542 7.23654C11.32 7.18697 10.8841 7.18699 10.372 7.18702L10.3355 7.18702H9.46286V4.00112L9.46286 3.97257C9.46288 3.65344 9.46289 3.37544 9.44128 3.17449C9.43039 3.07324 9.41024 2.94728 9.35872 2.83199C9.30254 2.7063 9.18012 2.53622 8.94819 2.48513C8.71625 2.43405 8.5337 2.53696 8.42992 2.62742C8.33473 2.71039 8.26352 2.81623 8.2111 2.90354C8.10706 3.07682 7.99029 3.32909 7.85623 3.61871L7.84424 3.64461L5.84435 7.96437L5.82902 7.99748C5.61386 8.46215 5.4307 8.85773 5.33528 9.18182C5.23536 9.52117 5.196 9.89696 5.41716 10.2431C5.63832 10.5892 5.99587 10.7113 6.3458 10.7632C6.67998 10.8128 7.11592 10.8128 7.62798 10.8127L7.66447 10.8127Z"
      fill="black"
      stroke="white"
      strokeWidth="0.925714"
    />
  </svg>
);
