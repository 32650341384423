import React from "react";

export const BackIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M4 12L3.29289 11.2929L2.58579 12L3.29289 12.7071L4 12ZM19 13C19.5523 13 20 12.5523 20 12C20 11.4477 19.5523 11 19 11V13ZM9.29289 5.29289L3.29289 11.2929L4.70711 12.7071L10.7071 6.70711L9.29289 5.29289ZM3.29289 12.7071L9.29289 18.7071L10.7071 17.2929L4.70711 11.2929L3.29289 12.7071ZM4 13H19V11H4V13Z"
      fill="currentcolor"
    />
  </svg>
);

export const CloseIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M18 6L6 18"
      stroke="currentcolor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6 6L18 18"
      stroke="currentcolor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const SolanaLogo: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="94"
    height="12"
    viewBox="0 0 94 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M27.7175 4.87199H20.8653V2.61599H29.4915V0.359985H20.8495C20.2493 0.359985 19.6737 0.596019 19.2493 1.01616C18.825 1.43631 18.5865 2.00615 18.5865 2.60032V4.88765C18.5865 5.48182 18.825 6.05166 19.2493 6.47181C19.6737 6.89195 20.2493 7.12799 20.8495 7.12799H27.7017V9.38399H18.7495V11.64H27.7175C28.3177 11.64 28.8933 11.404 29.3176 10.9838C29.742 10.5637 29.9805 9.99382 29.9805 9.39965V7.11232C29.9805 6.51815 29.742 5.94831 29.3176 5.52816C28.8933 5.10802 28.3177 4.87199 27.7175 4.87199Z"
      fill="black"
    />
    <path
      d="M40.9865 0.359985H34.1091C33.8119 0.359985 33.5178 0.417945 33.2433 0.530551C32.9689 0.643158 32.7195 0.808204 32.5095 1.01626C32.2995 1.22431 32.133 1.4713 32.0194 1.7431C31.9058 2.0149 31.8475 2.30618 31.8477 2.60032V9.39965C31.8475 9.69379 31.9058 9.98507 32.0194 10.2569C32.133 10.5287 32.2995 10.7757 32.5095 10.9837C32.7195 11.1918 32.9689 11.3568 33.2433 11.4694C33.5178 11.582 33.8119 11.64 34.1091 11.64H40.9865C41.5867 11.64 42.1624 11.404 42.5867 10.9838C43.0111 10.5637 43.2495 9.99382 43.2495 9.39965V2.60032C43.2495 2.00615 43.0111 1.43631 42.5867 1.01616C42.1624 0.596019 41.5867 0.359985 40.9865 0.359985ZM40.9708 9.38399H34.1344V2.61599H40.9708V9.38399Z"
      fill="black"
    />
    <path
      d="M65.0562 0.359986H58.3497C57.7495 0.359986 57.1738 0.596021 56.7494 1.01616C56.3251 1.43631 56.0867 2.00615 56.0867 2.60032V11.64H58.3655V7.93325H65.0404V11.64H67.3192V2.60032C67.3194 2.30606 67.261 2.01464 67.1473 1.74274C67.0337 1.47083 66.867 1.22378 66.6568 1.0157C66.4467 0.80763 66.1971 0.642616 65.9225 0.530102C65.6478 0.417587 65.3535 0.35978 65.0562 0.359986ZM65.0404 5.67725H58.3655V2.61599H65.0404V5.67725Z"
      fill="black"
    />
    <path
      d="M91.7369 0.35999H85.0319C84.7344 0.359372 84.4395 0.416864 84.1644 0.529175C83.8893 0.641485 83.6394 0.806409 83.4287 1.01451C83.218 1.2226 83.0509 1.46978 82.937 1.7419C82.8229 2.01401 82.7642 2.30573 82.7642 2.60032V11.64H85.043V7.93326H91.7211V11.64H93.9999V2.60032C93.9999 2.00615 93.7615 1.43631 93.3371 1.01617C92.9127 0.596025 92.3371 0.35999 91.7369 0.35999ZM91.7211 5.67726H85.0477V2.61599H91.7211V5.67726Z"
      fill="black"
    />
    <path
      d="M78.4615 9.38399H77.5499L74.2837 1.29999C74.172 1.02262 73.9788 0.784789 73.7292 0.617222C73.4795 0.449656 73.1848 0.360052 72.8832 0.359985H70.8545C70.4545 0.359985 70.0709 0.517286 69.7881 0.797285C69.5052 1.07728 69.3463 1.45704 69.3463 1.85302V11.64H71.6251V2.61599H72.5366L75.8013 10.7C75.9125 10.9774 76.1054 11.2152 76.3548 11.3828C76.6043 11.5505 76.8987 11.64 77.2002 11.64H79.229C79.629 11.64 80.0126 11.4827 80.2954 11.2027C80.5782 10.9227 80.737 10.5429 80.737 10.1469V0.359985H78.4583L78.4615 9.38399Z"
      fill="black"
    />
    <path
      d="M47.5475 0.359985H45.2687V9.39965C45.2687 9.99382 45.5071 10.5637 45.9315 10.9838C46.356 11.404 46.9315 11.64 47.5317 11.64H54.3838V9.38399H47.5475V0.359985Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.41173 8.96099C2.4522 8.9176 2.50108 8.88275 2.55546 8.85853C2.60985 8.83431 2.66861 8.8212 2.72824 8.81999H12.6726C12.7147 8.82057 12.7556 8.83318 12.7907 8.85629C12.8257 8.87941 12.8532 8.91205 12.8699 8.95027C12.8867 8.9885 12.8919 9.0307 12.8849 9.07179C12.878 9.11288 12.8593 9.15111 12.8309 9.18189L10.723 11.499C10.6825 11.5424 10.6336 11.5772 10.5793 11.6014C10.5249 11.6257 10.4661 11.6388 10.4065 11.64H0.455778C0.413703 11.6394 0.372695 11.6268 0.337686 11.6037C0.302678 11.5806 0.27516 11.5479 0.258443 11.5097C0.241726 11.4715 0.236522 11.4293 0.243455 11.3882C0.250389 11.3471 0.269166 11.3089 0.297529 11.2781L2.41173 8.96099ZM12.8404 7.04025C12.8688 7.07102 12.8875 7.10926 12.8944 7.15035C12.9014 7.19144 12.8962 7.23363 12.8794 7.27186C12.8627 7.3101 12.8352 7.34273 12.8002 7.36584C12.7651 7.38895 12.7242 7.40156 12.6821 7.40215L2.73456 7.40999C2.67495 7.40877 2.61617 7.39567 2.56179 7.37145C2.50741 7.34722 2.45852 7.31237 2.41807 7.26899L0.294364 4.95972C0.266002 4.92895 0.247225 4.89072 0.24029 4.84963C0.233356 4.80854 0.238561 4.76634 0.255278 4.72811C0.271995 4.68987 0.299512 4.65724 0.334521 4.63413C0.369529 4.61102 0.410538 4.59841 0.452614 4.59782L10.4002 4.58999C10.4598 4.5912 10.5185 4.6043 10.5729 4.62853C10.6273 4.65275 10.6762 4.6876 10.7167 4.73099L12.8404 7.04025ZM2.41173 0.500985C2.4522 0.457606 2.50108 0.422759 2.55546 0.398531C2.60985 0.374303 2.66861 0.361192 2.72824 0.359985L12.679 0.367819C12.721 0.368405 12.7621 0.381014 12.7971 0.404128C12.8321 0.427242 12.8595 0.459877 12.8762 0.498107C12.893 0.536338 12.8982 0.578535 12.8913 0.619624C12.8843 0.660714 12.8656 0.698945 12.8372 0.729719L10.723 3.03899C10.6825 3.08237 10.6336 3.11722 10.5793 3.14145C10.5249 3.16567 10.4661 3.17878 10.4065 3.17999H0.455778C0.413703 3.1794 0.372695 3.16679 0.337686 3.14368C0.302678 3.12057 0.27516 3.08792 0.258443 3.0497C0.241726 3.01147 0.236522 2.96927 0.243455 2.92818C0.250389 2.88709 0.269166 2.84886 0.297529 2.81809L2.41173 0.500985Z"
      fill="url(#goki_walletkit_solana_logo_fill)"
    />
    <defs>
      <linearGradient
        id="goki_walletkit_solana_logo_fill"
        x1="0.62194"
        y1="11.8859"
        x2="12.3933"
        y2="-0.00428666"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#9945FF" />
        <stop offset="0.14" stopColor="#8A53F4" />
        <stop offset="0.42" stopColor="#6377D6" />
        <stop offset="0.79" stopColor="#24B0A7" />
        <stop offset="0.99" stopColor="#00D18C" />
        <stop offset="1" stopColor="#00D18C" />
      </linearGradient>
    </defs>
  </svg>
);
